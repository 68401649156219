html, body, #root {
    height: 100%;
}

/*a {
    color: #0366d6;
}*/

a, .btn-link {
    color: var(--bs-primary);
    text-decoration: none;
    font-weight: 500;
    border-width: 0;
}

    a:hover {
        color: #005ba9;
        text-decoration: underline;
    }

h1 {
    font-size: 2rem;
}

.btn:focus, .btn:active:focus, .btn-link.nav-link:focus, .form-control:focus, .form-check-input:focus {
    box-shadow: 0 0 0 0.1rem white, 0 0 0 0.25rem #258cfb;
}

.container-fluid {
    max-width: 1100px;
}

code {
    color: #E01A76;
}

.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}

.rdrDefinedRangesWrapper {
    display: none;
}


.loader {
    color: rgba(var(--bs-body-color-rgb), 0.3);
    font-size: 20px;
    margin: 3.5em;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    text-indent: -9999em;
    -webkit-animation: load4 1.3s infinite linear;
    animation: load4 1.3s infinite linear;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
}

@-webkit-keyframes load4 {
    0%, 100% {
        box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
    }

    12.5% {
        box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }

    25% {
        box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }

    37.5% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }

    50% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }

    62.5% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
    }

    75% {
        box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
    }

    87.5% {
        box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
    }
}

@keyframes load4 {
    0%, 100% {
        box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
    }

    12.5% {
        box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }

    25% {
        box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }

    37.5% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }

    50% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }

    62.5% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
    }

    75% {
        box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
    }

    87.5% {
        box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
    }
}

@media (prefers-color-scheme: dark) {
    :root {
        --primary-contrast-color: #d0d0d0;
        --bs-body-color: #d0d0d0;
        --bs-body-color-rgb: 208, 208, 208;
        --bs-body-bg: #272727;
        --darker-bg: #1d1d1d;
        --bs-primary: #84b9d7; /* links */
        --bs-primary-rgb: 12, 70, 104; /* nav */
        --bs-light-rgb: 60, 60, 60;
        --bs-secondary-rgb: 170,170,170;
        --bs-green: #8bd08b;
        --bs-red: #ec8888
    }

    body {
        background-color: var(--darker-bg);
    }

    .modal-content, .list-group-item {
        background-color: var(--bs-body-bg);
    }

    .modal-header .btn-close {
        filter: invert(1) grayscale(100%) brightness(200%);
    }

    .dropdown-menu {
        background-color: var(--darker-bg);
    }

    .dropdown-item:hover {
        background-color: var(--bs-body-bg);
    }

    .dropdown-item, .dropdown-item:hover {
        color: var(--primary-contrast-color);
    }

    .table {
        --bs-table-striped-color: var(--bs-body-color);
        color: var(--bs-body-color);
        border-color: #585858;
    }

    .table-secondary {
        --bs-table-bg: var(--darker-bg);
        color: unset;
        border-color: #585858;
    }

    .btn-outline-primary {
        color: #1778b1;
        border-color: #1778b1;
    }

    .form-select, .form-select:focus, .form-control, .form-control:focus {
        color: var(--primary-contrast-color) !important;
        background-color: var(--darker-bg);
    }

    .form-check-input {
        background-color: #999999;
    }

    .input-group-text {
        color: var(--primary-contrast-color) !important;
        background-color: var(--bs-body-bg);
    }

    .form-control:disabled, .form-control[readonly], .form-control.disabled {
        background-color: var(--bs-body-bg);
    }

    .rdrCalendarWrapper {
        background-color: var(--darker-bg);
    }

    .rdrDateDisplayWrapper {
        background-color: var(--bs-body-bg);
    }

    .rdrDayNumber span {
        color: #dddddd;
    }
    .rdrNextPrevButton {
        background: #7f7f7f;
    }

    .rdrDateDisplayItem input {
        background-color: #666666;
        color: #dddddd;
    }

    .rdrDateDisplayItemActive input {
        background-color: #888888;
        color: #dddddd;
    }

}
